globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"989678320e85336bfc5dc613ca2c7d76a47fc315"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

if (process.env.NEXT_PUBLIC_SENTRY_ENABLED) {
  Sentry.init({
    dsn:
      process.env.NEXT_PUBLIC_SENTRY_DNS ||
      'https://5a7715066ae842a996e7b5015a6731c9@o285994.ingest.sentry.io/4504560541237248',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  });
}
